/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Container, Image, Row, Spinner } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useBetween } from "use-between";
import "./index.scss";
import Higher from "../../assets/images/dashboard/Higher.jpeg";
import PapaAndBarkley from "../../assets/images/dashboard/papa_and_barkley.jpg";
import LiftTickets from "../../assets/images/dashboard/LiftTickets.jpeg";
import Surplus from "../../assets/images/dashboard/Surplus.jpeg";
import Almora from "../../assets/images/dashboard/Almora.jpeg";
import BeardBros from "../../assets/images/dashboard/Beard-Bros.jpeg";
import HH from "../../assets/images/dashboard/HH.jpeg";
import SpotLightProduct from "../../assets/images/dashboard/spotlight-product.png";
import { productCategories, initialFeaturedImages } from "./landingPageData";
import { LeftArrow, RightArrow } from "../../components/icons";
import { Link, useNavigate } from "react-router-dom";
import CartLayout from "../../layout/CartLayout";
import useApi from "../useApi";
import { useEffect, useState, useMemo } from "react";
import ItemImage from "../../assets/images/collection/product.png";
import { toTwoDecimals } from "../../plugins";
import AwareButton from "../../components/button";

const productCats = [
    { id: "new-release", label: "New Releases" },
    { id: "limited-product", label: "Limited Supply" },
    { id: "fast-movers", label: "Fast Movers" }
];

const Landing = () => {
    const {
        updating,
        setUpdating,
        setCategoryFilters,
        getNewsletter,
        getSpotLightProduct,
        logout,
        getProductsByAvailability,
        getCustomerRewards
    } = useBetween(useApi);
    const navigate = useNavigate();
    const [homeBanners, setHomeBanners] = useState([...initialFeaturedImages]);

    const [productSpotLight, setProductSpotLight] = useState({
        imagePath: SpotLightProduct,
        title: "Product Spotlight",
        subTitle: "Fast Acting Gummies",
        features: `Fast-acting formulation encourages onset within 15 minutes. \nInfused with 99%+ pure THCA diamonds \nSour flavors made from real fruit concentrates`,
        buttonLabel: "Shop Now",
        buttonLink: "#"
    });

    const [activeProductCat, setActiveProductCat] = useState(productCats[0].id);
    const [productByAvailability, setProductByAvailability] = useState([]);

    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            className={
                "slick-prev slick-arrow" +
                (currentSlide === 0 ? " slick-disabled" : "")
            }
            aria-hidden='true'
            aria-disabled={currentSlide === 0 ? true : false}
            type='button'
        >
            <LeftArrow width='24' height='24' />
        </button>
    );

    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            className={
                "slick-next slick-arrow" +
                (currentSlide === slideCount - 1 ? " slick-disabled" : "")
            }
            aria-hidden='true'
            aria-disabled={currentSlide === slideCount - 1 ? true : false}
            type='button'
        >
            <RightArrow width='24' height='24' />
        </button>
    );

    const featureImagesSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,

        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    arrows: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                    centerMode: true,
                    centerPadding: "20px"
                }
            }
        ]
    };

    const productCatSettings = {
        dots: false,
        speed: 500,
        slidesToShow: 8,
        infinite: false,
        arrows: false,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,

        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 6.3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4.5
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3.5
                }
            }
        ]
    };

    const brandSettings = {
        dots: false,
        speed: 500,
        slidesToShow: 6,
        infinite: false,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,

        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2.5,
                    arrows: false
                }
            }
        ]
    };

    const shopByCategory = (category) => {
        setCategoryFilters([category]);
        category
            ? navigate(`/collection/all?category=${category}`)
            : navigate("/collection/all");
    };

    useEffect(() => {
        setUpdating(true);
        getNewsletter()
            .then((response) => {
                if (
                    response.type === "success" &&
                    response.newsletter.length > 0 &&
                    response.newsletter[0].banners.length > 0
                ) {
                    setHomeBanners(response.newsletter[0].banners);
                }
                setUpdating(false);
            })
            .catch(() => logout());
        getSpotLightProduct()
            .then((response) => {
                if (
                    response.type === "success" &&
                    response.spotlight.length > 0
                ) {
                    setProductSpotLight(response.spotlight[0]);
                }
                setUpdating(false);
            })
            .catch(() => logout());
    }, []);

    useEffect(() => {
        setUpdating(true);
        getProductsByAvailability(activeProductCat)
            .then((res) => {
                setProductByAvailability(res.products);
            })
            .finally(() => {
                setUpdating(false);
            });
    }, [activeProductCat]);

    return (
        <CartLayout>
            <div className='w-100 dashboard-container'>
                <div className='featured-images-wrapper'>
                    <Container>
                        <div>
                            <Slider {...featureImagesSettings}>
                                {homeBanners.map((item, index) => (
                                    <Link
                                        to={
                                            item.imageLink ||
                                            initialFeaturedImages[index]
                                                .imageLink
                                        }
                                        key={item._id}
                                    >
                                        <Image
                                            fluid
                                            src={
                                                item.imagePath ||
                                                initialFeaturedImages[index]
                                                    .imagePath
                                            }
                                            alt={item._id}
                                            style={{
                                                cursor: "pointer",
                                                marginRight: "10px"
                                            }}
                                        />
                                    </Link>
                                ))}
                            </Slider>
                        </div>
                    </Container>
                </div>
                <div className='product-categories-wrapper'>
                    <Container>
                        <Row className='justify-content-center'>
                            <Col lg='10'>
                                <Slider
                                    className='product-categories'
                                    {...productCatSettings}
                                >
                                    {productCategories.map(
                                        (productCat, index) => (
                                            <li
                                                key={index}
                                                onClick={() => {
                                                    shopByCategory(
                                                        productCat.value ===
                                                            "Shop All"
                                                            ? undefined
                                                            : productCat.value
                                                    );
                                                }}
                                                style={{
                                                    cursor: "pointer"
                                                }}
                                            >
                                                <div className='mb-2'>
                                                    {productCat.icon}
                                                </div>
                                                <div>{productCat.title}</div>
                                            </li>
                                        )
                                    )}
                                </Slider>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/* <div className='bundle-products '>
                    <Container>
                        <h5>Good things come in bundles</h5>
                        <Row>
                            <Col md={3} sm={6}>
                                <div className='bundle-product-block'>
                                    <div className='product-image'>
                                        <div className='product-tag'>
                                            Bundle deal
                                        </div>
                                        <Image
                                            src={BundleProduct}
                                            fluid
                                            alt='Almora 3.5g Flower Bundle'
                                        />
                                    </div>
                                    <div className='product-name'>
                                        Almora 3.5g Flower Bundle
                                    </div>
                                    <div className='offered-deal'>
                                        Buy 2 Cases, Get 1 Free
                                    </div>
                                    <div className='shop-link-btn'>
                                        <ShopIcon />
                                        Shop bundle
                                    </div>
                                </div>
                            </Col>
                            <Col md={3} sm={6}>
                                <div className='bundle-product-block'>
                                    <div className='product-image'>
                                        <Image
                                            src={BundleProduct}
                                            fluid
                                            alt='Almora 3.5g Flower Bundle'
                                        />
                                    </div>
                                    <div className='product-name'>
                                        Almora 3.5g Flower Bundle
                                    </div>
                                    <div className='offered-deal'>
                                        Buy 2 Cases, Get 1 Free
                                    </div>
                                    <div className='shop-link-btn'>
                                        <ShopIcon />
                                        Shop bundle
                                    </div>
                                </div>
                            </Col>
                            <Col md={3} sm={6}>
                                <div className='bundle-product-block'>
                                    <div className='product-image'>
                                        <Image
                                            src={BundleProduct}
                                            fluid
                                            alt='Almora 3.5g Flower Bundle'
                                        />
                                    </div>
                                    <div className='product-name'>
                                        Almora 3.5g Flower Bundle
                                    </div>
                                    <div className='offered-deal'>
                                        Buy 2 Cases, Get 1 Free
                                    </div>
                                    <div className='shop-link-btn'>
                                        <ShopIcon />
                                        Shop bundle
                                    </div>
                                </div>
                            </Col>
                            <Col md={3} sm={6}>
                                <div className='bundle-product-block'>
                                    <div className='product-image'>
                                        <Image
                                            src={BundleProduct}
                                            fluid
                                            alt='Almora 3.5g Flower Bundle'
                                        />
                                    </div>
                                    <div className='product-name'>
                                        Almora 3.5g Flower Bundle
                                    </div>
                                    <div className='offered-deal'>
                                        Buy 2 Cases, Get 1 Free
                                    </div>
                                    <div className='shop-link-btn'>
                                        <ShopIcon />
                                        Shop bundle
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div> */}
                <div className='new-release-products'>
                    <Container>
                        <h5>
                            {productCats.map((prodCat) => (
                                <span
                                    key={prodCat.id}
                                    onClick={() =>
                                        setActiveProductCat(prodCat.id)
                                    }
                                    className={`${activeProductCat === prodCat.id
                                        ? "active"
                                        : ""
                                        }`}
                                >
                                    {prodCat.label}
                                </span>
                            ))}
                            <span className='last-child'>
                                <Link
                                    to={`/collection/all?availability=${activeProductCat}`}
                                >
                                    See all <RightArrow />
                                </Link>
                            </span>
                        </h5>
                        <Row>
                            {productByAvailability.map((newReleaseItem) => (
                                <Col key={newReleaseItem.id} xl={3} md={6}>
                                    <Link
                                        to={`/product/${newReleaseItem._id}`}
                                        className='new-item'
                                    >
                                        <div className='new-product-image'>
                                            <div className='img-wrapper'>
                                                {newReleaseItem
                                                    .Primaryproductimagefirstdisplayed
                                                    .value ? (
                                                    <Image
                                                        src={
                                                            newReleaseItem
                                                                .Primaryproductimagefirstdisplayed
                                                                .value
                                                        }
                                                        alt='product'
                                                    />
                                                ) : (
                                                    <Image
                                                        src={ItemImage}
                                                        alt='product'
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <div className='new-item-wrapper'>
                                            <h6 className='cursor-pointer'>
                                                {`${newReleaseItem.Brand.value} | ${newReleaseItem.Buyermenuproductname.value}`}
                                            </h6>
                                            <label>
                                                <span className='product-name'>
                                                    {
                                                        newReleaseItem
                                                            .StrainDescription
                                                            .value
                                                    }
                                                </span>
                                                <span
                                                    className='green-tg'
                                                    data-phenotype={
                                                        newReleaseItem.Phenotype
                                                    }
                                                >
                                                    <span>
                                                        {" "}
                                                        {newReleaseItem.Phenotype.charAt(
                                                            0
                                                        ).toUpperCase()}
                                                    </span>
                                                </span>
                                            </label>
                                            <div className='product-price-info d-inline-flex'>
                                                <label className='price-per-unit'>{`$${toTwoDecimals(
                                                    newReleaseItem.DefaultPrice
                                                        .value
                                                )}`}</label>
                                                <label className='offered-price-info'>{`$${toTwoDecimals(
                                                    newReleaseItem.DefaultPrice
                                                        .value *
                                                    newReleaseItem.Casesize
                                                        .value
                                                )} / case (${newReleaseItem.Casesize
                                                    .value
                                                    } units)`}</label>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                            ))}
                            {productByAvailability.length <= 0 && (
                                <div className='p-3 b-1'>
                                    <h6>Currently no products available !</h6>
                                </div>
                            )}
                        </Row>
                    </Container>
                </div>

                <div className='brands-wrapper'>
                    <Container>
                        <div className='title-wrapper'>
                            <h3>Shop by brand</h3>
                        </div>
                        <Slider {...brandSettings}>
                            <div className='img-brand first-item'>
                                <Link to='/collection/all?brand=Heavy Hitters'>
                                    <Image src={HH} />
                                </Link>
                            </div>
                            <div className='img-brand'>
                                <Link to='/collection/all?brand=Almora'>
                                    <Image src={Almora} />
                                </Link>
                            </div>
                            <div className='img-brand'>
                                <Link to='/collection/all?brand=Lift Tickets'>
                                    <Image src={LiftTickets} />
                                </Link>
                            </div>
                            <div className='img-brand'>
                                <Link to={`/collection/all?brand=${'Papa %26 Barkley'}`}>
                                    <Image src={PapaAndBarkley} />
                                </Link>
                            </div>
                            <div className='img-brand'>
                                <Link to='/collection/all?brand=Surplus'>
                                    <Image src={Surplus} />
                                </Link>
                            </div>
                            <div className='img-brand last-item'>
                                <Link to='/collection/all?brand=Beard Brothers'>
                                    <Image src={BeardBros} />
                                </Link>
                            </div>
                        </Slider>
                    </Container>
                </div>

                <div className='spotlight-product-wrapper'>
                    <Container>
                        <Row className='justify-content-center'>
                            <Col md={10}>
                                <Row className='spotlight-product-block align-items-center'>
                                    <Col sm={7} className='text-end'>
                                        <div className='product-image'>
                                            <Image
                                                fluid
                                                src={productSpotLight.imagePath}
                                                alt={productSpotLight.title}
                                            />
                                        </div>
                                    </Col>
                                    <Col sm={5}>
                                        <div className='product-detail'>
                                            <div className='product-title'>
                                                {productSpotLight.title}
                                            </div>
                                            <div className='product-name'>
                                                {productSpotLight.subTitle}
                                            </div>
                                            <div className='product-desp'>
                                                <ul>
                                                    {productSpotLight.features
                                                        .split("\n")
                                                        .map((feature) => (
                                                            <li key={feature}>
                                                                {feature}
                                                            </li>
                                                        ))}
                                                </ul>
                                            </div>
                                            <div className='product-action'>
                                                <AwareButton
                                                    href={
                                                        productSpotLight.buttonLink
                                                    }
                                                    className='btn btn-lg btn-bottom btn-outline'
                                                >
                                                    {
                                                        productSpotLight.buttonLabel
                                                    }
                                                    <RightArrow />
                                                </AwareButton>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            {updating ? (
                <div className='update-loading'>
                    <Spinner animation='border' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                    </Spinner>
                </div>
            ) : null}
        </CartLayout>
    );
};

export default Landing;
