/* eslint-disable react-hooks/exhaustive-deps */
import {Image} from "react-bootstrap";
import {useBetween} from "use-between";

import ItemImage from "../../assets/images/collection/product.png";
import {TrashIcon} from "../icons";
import useApi from "../../pages/useApi";
import {toast} from "react-toastify";
import {toTwoDecimals} from "../../plugins";
import {useNavigate} from "react-router-dom";

const CartItem = ({item}) => {
    const {cart, cartProducts, updateCart, setUpdating} = useBetween(useApi);
    const navigate = useNavigate();
    const addToCart = (item) => {
        setUpdating(true);
        let formData = {};
        const products = cart.products;
        const duplicatedId = products.findIndex(
            (product) => product.productId === item._id
        );

        products[duplicatedId].quantity =
            products[duplicatedId].quantity + item.Casesize.value;
        formData = {
            sessionId: cart.customerId,
            customerId: cart.customerId,
            products: [...products]
        };

        updateCart(cart._id, formData)
            .then(() => {
                // toast.success("Cart Updated");
                setUpdating(false);
            })
            .catch((err) => {
                toast.error(err.message);
                setUpdating(false);
            });
    };

    const removeToCart = (item) => {
        setUpdating(true);
        let formData = {};
        const products = cart.products;
        const duplicatedId = products.findIndex(
            (product) => product.productId === item._id
        );

        if (products[duplicatedId].quantity <= item.Casesize.value) {
            products.splice(duplicatedId, 1);
        } else {
            products[duplicatedId].quantity =
                products[duplicatedId].quantity - item.Casesize.value;
        }

        formData = {
            sessionId: cart.customerId,
            customerId: cart.customerId,
            products: [...products]
        };
        updateCart(cart._id, formData)
            .then(() => {
                // toast.success("Cart Updated");
                setUpdating(false);
            })
            .catch((err) => {
                toast.error(err.message);
                setUpdating(false);
            });
    };

    const removeProduct = (item) => {
        setUpdating(true);

        let formData = {};
        const products = cart.products;
        const duplicatedId = products.findIndex(
            (product) => product.productId === item._id
        );
        products.splice(duplicatedId, 1);
        formData = {
            sessionId: cart.customerId,
            customerId: cart.customerId,
            products: [...products]
        };
        updateCart(cart._id, formData)
            .then(() => {
                setUpdating(false);
            })
            .catch((err) => {
                toast.error(err.message);
                setUpdating(false);
            });
    };

    return (
        <div className='cart-item' data-attr={item._id}>
            <div className='cart-product-content'>
                <div className='cart-product-image'>
                    <div className='img-wrapper'>
                        {item
                            .Primaryproductimagefirstdisplayed.value ? (
                            <Image
                                src={
                                    item
                                        .Primaryproductimagefirstdisplayed.value
                                }
                                alt='product'
                            />
                        ) : (
                            <Image src={ItemImage} alt='product'/>
                        )}
                    </div>
                </div>
                <div className='cart-item-wrapper'>
                    <h6
                        onClick={() =>
                            navigate(
                                `/product/${item._id}`
                            )
                        }
                    >
                        {`${item.Brand.value} | ${item.Buyermenuproductname.value}`}
                    </h6>
                    <label
                        className='cart-item-name'
                        onClick={() =>
                            navigate(
                                `/product/${item._id}`
                            )
                        }
                    >
                        {item.StrainDescription.value}
                        {item.Phenotype ? (
                            <span
                                className='green-tg'
                                data-phenotype={
                                    item.Phenotype
                                }
                            >
                                <span>
                                    {item.Phenotype.charAt(0).toUpperCase()}
                                </span>
                            </span>
                        ) : null}
                    </label>
                    <div className='product-price-info d-inline-flex'>
                        {/* <label className='price-per-unit'>{`$${toTwoDecimals(
                        item.DefaultPrice.value
                    )} / unit`}</label> */}
                        <label className='price-per-unit'>{`$${toTwoDecimals(
                            item.DefaultPrice.value
                        )}`}</label>
                        <label className='offered-price-info'>{`$${toTwoDecimals(
                            item.DefaultPrice.value *
                            item.Casesize.value
                        )} / case (${
                            item.Casesize.value
                        } units)`}</label>
                    </div>
                    <div className='qty-container d-inline-flex align-items-center'>
                        <div className='qty-wrapper'>
                            <span
                                onClick={() => {
                                    removeToCart(item);
                                }}
                                className='cursor-pointer qty'
                            >
                                -
                            </span>
                            <span
                                className='qty-value'>{cart.products.find(product => product.productId === item._id)?.quantity}</span>
                            <span
                                onClick={() => {
                                    addToCart(item);
                                }}
                                className='cursor-pointer qty'
                            >
                                +
                            </span>
                        </div>
                        <div className='d-flex ml-4 price-container'>
                            <p className=''>{`$${toTwoDecimals(
                                item.DefaultPrice
                                    .value * cart.products.find(product => product.productId === item._id)?.quantity
                            )}`}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='cart-image-action'>
                <span
                    className='btn-remove'
                    onClick={() => {
                        removeProduct(item);
                    }}
                >
                    <TrashIcon width='24' height='24'/>
                </span>
            </div>
        </div>
    );
};

export default CartItem;
