/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from "react";
import {useBetween} from "use-between";
import moment from "moment-timezone";
import {XIcon} from "../icons";
import useApi from "../../pages/useApi";

import "./index.scss";
import CartItem from "./item";
import AwareButton from "../button";
import {toTwoDecimals, toFormattedNumbers} from "../../plugins";
import CartTimer from "../cart-timer";
import {useNavigate, Link} from "react-router-dom";
import {Button, Image, Spinner} from "react-bootstrap";
import {toast} from "react-toastify";
import SimilarProductsModal from "../../pages/order/SimilarProductsModal";
import OutOfStockProductsModal from "../../pages/order/OutOfStockProductsModal";
import GiftBoxIcon from "../../assets/images/gift/giftBoxIcon.svg";
import SuccessModal from "../../pages/order/SuccessModal";
import CartExpiredAlertModal from "./CartExpiredAlertModal";
import NotFoundIcon from "./../../assets/images/profile/404.svg";

const Cart = ({status, setStatus, currentPage, handlePlaceOrders}) => {
    const [cartTimeExpired, setCartTimeExpired] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showProductsModal, setShowProductsModal] = useState(false);
    const [showSimilarProductsModal, setShowSimilarProductsModal] = useState(
        false
    );
    const [unavailableProducts, setUnavailableProducts] = useState([]);
    const [similarProducts, setSimilarProducts] = useState([]);
    const [findSimilarProductId, setFindSimilarProductId] = useState("");
    const {
        cart,
        setCart,
        cartTime,
        setCartTime,
        customerId,
        multipleCustomers,
        cartProducts,
        getCart,
        setCartAmount,
        totalPrice,
        setTotalPrice,
        originalPrice,
        setOriginalPrice,
        cartOpen,
        setCartOpen,
        reviewOrder,
        setShowGamificationPopOver,
        rewards,
        rewardsThreshold,
        customerRewardItem,
        productByBatch,
        updating
    } = useBetween(useApi);
    const navigate = useNavigate();

    const handleReviewOrders = () => {
        if (cart.products.length > 0) {
            navigate(`/checkout`);
        } else {
            toast.error("Please add products before proceeding.");
        }
    };

    useEffect(() => {
        if (customerId) {
            getCart(customerId).then((response) => {
                if (!response.cart.SessionTime) {
                    handleRebuildCart();
                }
            });
        }
    }, [customerId]);

    useEffect(() => {
        let price = 0;
        let originalPrice = 0;

        if (Object.keys(cart).length) {
            cart.products.map((item) => {
                price +=
                    item.quantity *
                    cartProducts[item.productId].DefaultPrice.value;
                originalPrice +=
                    item.quantity *
                    cartProducts[item.productId].OriginalPrice.value;
            });

            setTotalPrice(price);
            setOriginalPrice(originalPrice);
            setCartAmount(cart.products.length);
        }
    }, [cart]);

    const removeProduct = (productId) => {
        const productIndex = unavailableProducts.findIndex(
            (product) => product.id === productId
        );
        unavailableProducts.splice(productIndex, 1);
        setUnavailableProducts([...unavailableProducts]);
    };

    const handleNavigation = () => {
        navigate(`/collection/all`);
        setCartOpen(!cartOpen);
    };

    const convertIntoNumber = (cartSessionTime) => {
        const actualReserveTime = 60 * 30;
        const timeDiff = moment().diff(cartSessionTime, "seconds");
        return actualReserveTime - timeDiff > 0
            ? actualReserveTime - timeDiff
            : 0;
    };

    const handleRebuildCart = () => {
        reviewOrder()
            .then((response) => {
                cart.products = response.availableProduct;
                if (response.unavailableProducts.length > 0) {
                    setUnavailableProducts(response.unavailableProducts);
                    setShowProductsModal(true);
                } else {
                    setShowSuccessModal(true);
                }
                setCart(cart);
                setCartTimeExpired(false);
                setCartTime(convertIntoNumber(response.SessionTime));
            })
            .catch((err) => {
                console.log("handleRebuildCart error", err);
            });
    };


    return (
        <div className={`cart-drawer ${status ? "active" : ""}`}>
            <div
                className={`cart-wrapper ${
                    multipleCustomers ? "hasMultipleCustomers" : ""
                }`}
            >
                <div className='mobile-menu-header d-flex d-md-none justify-content-between align-items-center'>
                    <h3>Cart</h3>
                    {/* <XIcon
                        className='cursor-pointer'
                        width='24'
                        height='24'
                        onClick={() => setStatus(false)}
                    /> */}
                </div>
                <div className='cart-drawer-header'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h4>Order Summary</h4>
                        <Button
                            variant='link'
                            size='sm'
                            className='btn-close'
                            aria-label='Remove'
                            onClick={() => setStatus(false)}
                        ></Button>
                    </div>
                    {Object.keys(cart).length > 0 &&
                        cart.products.length > 0 && (
                            <CartTimer
                                showTimeOnly={false}
                                rebuildCart={handleRebuildCart}
                                setCartTimeExpired={setCartTimeExpired}
                            />
                        )}
                </div>
                <div
                    className={`items-container ${
                        (cartTime <= 0 || cartTimeExpired) &&
                        Object.keys(cart).length > 0 &&
                        cart.products.length > 0
                            ? "cart-time-expired"
                            : ""
                    }`}
                >
                    {Object.keys(cart).length > 0 &&
                    cart.products.length > 0 ? (
                        Object.entries(productByBatch).map(([key, value]) => (
                            <div key={key}>

                                <h6 style={{padding: '15px 20px', fontSize: '14px'}}>{key}</h6>

                                {
                                    value.map((item) => (
                                        <CartItem key={item.productId} item={item}/>
                                    ))
                                }
                            </div>


                        ))
                    ) : (
                        <div
                            className='empty-cart-block text-center h-100 d-flex align-items-center justify-content-center'>
                            <div>
                                <Image
                                    src={NotFoundIcon}
                                    className='mb-2'
                                    alt='404 icon'
                                />
                                <p className='py-3 px-2 no-items-text'>
                                    Your cart is empty
                                </p>
                                <AwareButton
                                    href={`/collection/all`}
                                    className='btn btn-green btn-lg btn-rebuild'
                                >
                                    Start Shopping
                                </AwareButton>
                            </div>
                        </div>
                    )}
                </div>
                <div
                    className={`order-btn-container ${
                        (cartTime <= 0 || cartTimeExpired) &&
                        Object.keys(cart).length > 0 &&
                        cart.products.length > 0
                            ? "cart-time-expired"
                            : ""
                    }`}
                >
                    {rewards.length > 0 && rewardsThreshold && (
                        <div className='gift-item-wrapper'>
                            <div className='d-flex align-items-center'>
                                <Image src={GiftBoxIcon}/>
                                {totalPrice < rewardsThreshold.threshold1 ? (
                                    <div>{`Add $${toTwoDecimals(
                                        rewardsThreshold.threshold1 - totalPrice
                                    )} to unlock a reward.`}</div>
                                ) : customerRewardItem ? (
                                    <div>
                                        {`Free ${customerRewardItem.ItemName.value}. `}
                                        <span
                                            className='text-link'
                                            onClick={() =>
                                                setShowGamificationPopOver(true)
                                            }
                                        >
                                            Change
                                        </span>
                                    </div>
                                ) : (
                                    <div
                                        className='text-link'
                                        onClick={() =>
                                            setShowGamificationPopOver(true)
                                        }
                                    >{`Select free gift`}</div>
                                )}
                            </div>
                        </div>
                    )}
                    <div className='action-button-wrapper'>
                        <div className='total-price d-inline-flex'>
                            <label className='me-3'>Total:</label>
                            <p>
                                {originalPrice !== totalPrice ? (
                                    <span className='origin'>
                                        ${toTwoDecimals(originalPrice)}
                                    </span>
                                ) : null}
                                {totalPrice > 0 &&
                                    `$ ${toTwoDecimals(totalPrice)}`}
                            </p>
                        </div>
                        {currentPage === "checkout" ? (
                            <AwareButton
                                className='btn-review btn-atc primary'
                                onClick={() => !updating && handlePlaceOrders()}
                                disabled
                            >
                                {!updating ? (
                                    "Place Order"
                                ) : (
                                    <>
                                        <Spinner size='sm' className='me-2'/>
                                        Ordering
                                    </>
                                )}
                            </AwareButton>
                        ) : (
                            <AwareButton
                                className='btn-review btn-atc primary'
                                onClick={() => handleReviewOrders()}
                            >
                                Review Order
                            </AwareButton>
                        )}
                    </div>
                </div>
            </div>
            {Object.keys(cart).length && cart.products?.length > 0 && (
                <CartExpiredAlertModal
                    show={cartTime <= 0 || cartTimeExpired ? true : false}
                    rebuildCart={handleRebuildCart}
                    handleClose={() => {
                        console.log("It has to be close");
                    }}
                />
            )}
            <SuccessModal
                show={showSuccessModal}
                handleClose={() => setShowSuccessModal(false)}
            />
            <OutOfStockProductsModal
                unavailableProducts={unavailableProducts}
                setFindSimilarProductId={setFindSimilarProductId}
                findSimilarProductId={findSimilarProductId}
                setSimilarProducts={setSimilarProducts}
                show={showProductsModal}
                setShowSimilarProductsModal={setShowSimilarProductsModal}
                handleClose={() => setShowProductsModal(false)}
                removeProduct={removeProduct}
            />
            <SimilarProductsModal
                show={showSimilarProductsModal}
                similarProducts={similarProducts}
                findSimilarProductId={findSimilarProductId}
                handleClose={() => setShowSimilarProductsModal(false)}
                handleBack={() => {
                    setShowProductsModal(true);
                    setShowSimilarProductsModal(false);
                }}
                handleDone={(pId) => {
                    setShowProductsModal(true);
                    setShowSimilarProductsModal(false);
                    unavailableProducts.length > 1
                        ? removeProduct(pId)
                        : handleNavigation();
                }}
            />
        </div>
    );
};

export default Cart;
